/* Container for the entire patient dashboard */
.patient-dashboard-container {
  max-width: 1000px;
  margin: 50px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  animation: fadeIn 1s ease-in-out;
}

/* Tab Navigation */
.tab-navigation {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tab-navigation button {
  padding: 10px 20px;
  border: none;
  background-color: #17a2b8;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 0 10px;
  border-radius: 5px;
  font-size: 16px;
}

.tab-navigation button.active {
  background-color: #138496;
}

.tab-navigation button:hover {
  background-color: #138496;
}

/* Details and Secret Code Sections */
.details,
.secret-code {
  margin-bottom: 20px;
}

.details p,
.secret-code p {
  font-size: 1.1em;
}

.secret-code {
  display: flex;
  align-items: center;
  gap: 15px; /* Space between elements */
}

.regenerate-button {
  padding: 10px;
  background-color: #070707;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.regenerate-button:hover {
  background-color: #eaeae9;
  color: rgb(1, 1, 1);
}

.regenerate-button.spinning {
  opacity: 0.6; /* Dull the button when loading */
  animation: spin 1s linear infinite;
}

/* View Past Prescriptions Button */
.view-past-button,
.logout-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.view-past-button:hover,
.logout-button:hover {
  background-color: #0056b3;
}

.view-past-button:disabled,
.logout-button:disabled {
  background-color: #aaa;
  cursor: not-allowed;
}

/* Prescriptions Grid */
.prescriptions-grid {
  margin-top: 30px;
  max-height: 400px;
  overflow-y: auto;
  padding-right: 10px; /* Prevent scrollbar overlap */
}

.prescription-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.prescription-card {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.prescription-card p {
  margin: 10px 0;
  font-size: 14px;
  color: #333;
}

.prescription-card img {
  max-width: 100%;
  border-radius: 8px;
  margin-bottom: 10px;
}

.prescription-card button {
  background-color: #17a2b8;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 14px;
}

.prescription-card button:hover {
  background-color: #138496;
}

/* Charts Container */
.charts-container {
  margin-top: 50px;
  padding: 20px;
  background-color: #f4f6f9;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.chart {
  margin-bottom: 40px;
}

/* Logout Button */
.logout-button {
  margin-top: 30px;
  padding: 10px 20px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: #c82333;
}

/* Loader */
.loader {
  text-align: center;
  font-size: 1.5em;
  padding: 50px;
}

/* Custom Scrollbar for Prescriptions Grid */
.prescriptions-grid::-webkit-scrollbar {
  width: 8px;
}

.prescriptions-grid::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.prescriptions-grid::-webkit-scrollbar-thumb {
  background: #17a2b8;
  border-radius: 10px;
}

.prescriptions-grid::-webkit-scrollbar-thumb:hover {
  background: #138496;
}

/* Popup Styles */
.prescription-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.prescription-popup {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  max-width: 800px;
  width: 90%;
  max-height: 90%; /* Ensure the popup doesn't overflow the screen */
  display: flex;
  flex-direction: column;
  overflow: hidden;
  animation: fadeIn 0.3s ease;
}

.close-popup-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
  transition: color 0.3s ease;
}

.close-popup-button:hover {
  color: #ff0000;
}

.prescription-details {
  text-align: left;
  overflow-y: auto;
  flex-grow: 1;
  margin-bottom: 10px;
  padding-right: 10px; /* Prevent scrollbar overlap */
}

.prescription-image {
  max-height: 300px; /* Set max height for the image container */
  overflow-y: auto;
  margin-top: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.prescription-image img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  display: block;
}

/* More Button Style */
.more-button {
  background-color: #17a2b8;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 14px;
}

.more-button:hover {
  background-color: #138496;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.view-past-button {
  margin-top: 20px;
  margin-right: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.view-past-button:hover {
  background-color: #0056b3;
}

.logout-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: #c82333;
}

.logout-button:disabled {
  background-color: #aaa;
  cursor: not-allowed;
}
.prescription-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.prescription-card {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.prescription-card img {
  max-width: 100%;
  max-height: 150px;
  object-fit: cover;
  border-radius: 8px;
}

.prescription-card p {
  margin: 10px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.more-button {
  background-color: #17a2b8;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.more-button:hover {
  background-color: #138496;
}

.chatbot-container {
  margin-top: 20px;
}

.chat-window {
  max-height: 300px;
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  margin-bottom: 10px;
}

.chat-message {
  margin: 5px 0;
  padding: 10px;
  border-radius: 8px;
}

.user-message {
  background-color: #d1e7ff;
  text-align: right;
}

.bot-message {
  background-color: #f8d7da;
  text-align: left;
}

.chat-input-container {
  display: flex;
  gap: 10px;
}

.chat-input-container input {
  flex-grow: 1;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.chat-input-container button {
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #17a2b8;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.chat-input-container button:disabled {
  background-color: #aaa;
}
.robot-animation {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.robot-runner {
  width: 50px;
  height: 50px;
  background: url('loading.gif') no-repeat;
  animation: robotRun 1s steps(10) infinite;
}
.form-container {
  max-width: 600px;
  margin: 0 auto;
}

.form-container h3 {
  text-align: center;
  margin-bottom: 20px;
}

.form-question {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.form-question label {
  margin-bottom: 5px;
}

.text-input, .number-input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.color-input {
  padding: 5px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

button[type="submit"] {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button[type="submit"]:disabled {
  background-color: #999;
}

.loader {
  margin: 10px auto;
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #007bff;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.chat-responses {
  margin-top: 20px;
}

.chat-message {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}
.slider-input {
  width: 100%;
  margin: 10px 0;
}
.backArrow:hover{
    background-color: #000000;
    color: #ffffff;
}
.slider-input {
  width: 100%;
  -webkit-appearance: none;
  height: 8px;
  border-radius: 5px;
  background: linear-gradient(90deg, #007bff, #00d4ff);
  outline: none;
  opacity: 0.9;
  transition: opacity 0.2s;
}

.slider-input:hover {
  opacity: 1;
}

.slider-input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #fff;
  border: 2px solid #007bff;
  cursor: pointer;
  transition: background 0.2s;
}

.slider-input::-webkit-slider-thumb:hover {
  background: #00d4ff;
}

.slider-input::-moz-range-thumb {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #fff;
  border: 2px solid #007bff;
  cursor: pointer;
  transition: background 0.2s;
}

.slider-input::-moz-range-thumb:hover {
  background: #00d4ff;
}

.form-question span {
  margin-left: 10px;
  font-weight: bold;
  font-size: 0.7rem;
  color: #007bff;
}