/* Global styles for the landing page */
body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
}

/* Hero Section */
.landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 50px 100px;
  background-color: #17a2b8;
  color: white;
  animation: fadeIn 1.5s ease-in-out;
}

.hero-content {
  max-width: 50%;
}

.hero-content h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.hero-content p {
  font-size: 1.5rem;
  margin-bottom: 40px;
}

.buttons-container {
  display: flex;
  gap: 20px;
}

.cta-button {
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.cta-button:hover {
  background-color: #138496;
  transform: translateY(-3px);
}

.cta-button.secondary {
  background-color: #ffffff;
  color: #17a2b8;
}

.cta-button.secondary:hover {
  background-color: #e0f7fa;
  transform: translateY(-3px);
}

.hero-image {
  max-width: 40%;
}

.hero-image img {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

/* Features Section */
.features-section {
  padding: 60px 100px;
  text-align: center;
  background-color: #f8f9fa;
}

.features-section h2 {
  font-size: 2.5rem;
  margin-bottom: 50px;
}

.features-grid {
  display: flex;
  justify-content: space-between;
}

.feature-card {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.feature-card img {
  width: 100%;
  border-radius: 5px;
  margin-bottom: 20px;
}

.feature-card h3 {
  font-size: 1.8rem;
  margin-bottom: 15px;
}

.feature-card p {
  font-size: 1.1rem;
  color: #555;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
