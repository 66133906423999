.footer {
    background-color: black;
    color: white;
    text-align: center;
    padding: 20px;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    font-size: 16px;
  }
  
  .footer p {
    margin: 0;
  }
  
  .footer a {
    color: #00acee;
    text-decoration: none;
  }
  
  .footer a:hover {
    color: #007bff;
  }
  .footer {
    text-align: center;
    padding: 20px;
    font-size: 14px;
    color: #888;
    margin-top: 20px;
    background-color: #1a1a1a;
  }
  
  .footer a {
    color: #00b4d8;
    text-decoration: none;
    font-weight: bold;
  }
  
  .footer a:hover {
    text-decoration: underline;
  }
  
  .copyright {
    margin-top: 10px;
    font-size: 12px;
    color: #aaa;
  }
  