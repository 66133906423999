.prescription-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .prescription-popup {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    position: relative;
    width: 90%;
    max-width: 600px;
    max-height: 90%; /* Ensure the popup doesn't overflow the screen */
    display: flex;
    flex-direction: column;
    overflow: hidden;
    animation: fadeIn 0.3s ease-in-out;
  }
  
  .close-popup-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #333;
    transition: color 0.3s ease;
  }
  
  .close-popup-button:hover {
    color: #ff0000;
  }
  
  .prescription-details {
    text-align: left;
    overflow-y: auto;
    flex-grow: 1;
    margin-bottom: 10px;
    padding-right: 10px; /* Prevent scrollbar overlap */
  }
  
  .prescription-image {
    max-height: 300px; /* Set max height for the image container */
    overflow-y: auto;
    margin-top: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  }
  
  .prescription-image img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    display: block;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Style the scrollbar for the popup */
  .prescription-popup ::-webkit-scrollbar {
    width: 8px;
  }
  
  .prescription-popup ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }
  
  .prescription-popup ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
  
  .prescription-popup ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
  