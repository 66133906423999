/* Global styles for header */
.header {
    background-color: black;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    animation: slideDown 0.5s ease-in-out;
  }
  
  .logo-container {
    display: flex;
    align-items: center;
  }
  
  .logo {
    height: 40px;
    width: 40px;
    margin-right: 10px;
    border-radius: 50%;
    transition: transform 0.3s ease;
  }
  
  .header-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: white;
    margin: 0;
  }
  
  .github-link {
    color: white;
    font-size: 1.8rem;
    transition: color 0.3s ease, transform 0.3s ease;
  }
  
  .github-icon {
    font-size: 24px;
  }
  
  .github-link:hover .github-icon {
    color: #007bff;
    transform: scale(1.2);
  }
  
  .header:hover .logo {
    transform: rotate(360deg);
  }
  
  @keyframes slideDown {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }
  