.login-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
  }
  
  .login-form label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .login-form input {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .login-form button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .login-form button:hover {
    background-color: #0056b3;
  }

  .signup-text {
    margin-top: 10px;
    font-size: 0.9em;
    color: #666;
  }
  
  .signup-text a {
    color: #007bff;
    text-decoration: none;
  }
  
  .signup-text a:hover {
    text-decoration: underline;
  }
  
  