.doctor-dashboard-container {
  max-width: 800px;
  margin: 50px auto;
  padding: 25px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  animation: fadeIn 1s ease-in-out;
}

.section {
  margin-bottom: 40px;
}

.section h3 {
  margin-bottom: 15px;
  color: #333;
}

/* Inputs and File Upload Styling */
.section input[type="text"],
.section input[type="file"],
.prescription-form input[type="text"],
.prescription-form input[type="file"],
.prescription-form textarea {
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  box-sizing: border-box;
}

/* Input Focus Styles */
.section input[type="text"]:focus,
.prescription-form input[type="text"]:focus,
.prescription-form textarea:focus {
  border-color: #17a2b8;
  box-shadow: 0 0 5px rgba(23, 162, 184, 0.5);
}

/* Buttons */
.section button,
.prescription-form button,
.logout-button {
  padding: 12px 20px;
  background-color: #17a2b8;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

/* Button Hover and Active States */
.section button:hover,
.prescription-form button:hover,
.logout-button:hover {
  background-color: #138496;
  transform: translateY(-3px);
}

.section button:active,
.prescription-form button:active,
.logout-button:active {
  transform: translateY(-1px);
}

.logout-button {
  background-color: #dc3545;
}

.logout-button:hover {
  background-color: #c82333;
}

/* Prescriptions List Styling */
.prescriptions-list ul {
  list-style-type: none;
  padding: 0;
}

.prescriptions-list li {
  background: #f8f9fa;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.prescriptions-list li:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Form Labels */
.prescription-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

/* Form Textarea */
.prescription-form textarea {
  min-height: 120px;
  resize: vertical;
}

/* Blood Pressure Inputs */
.blood-pressure-inputs {
  display: flex;
  align-items: center;
}

.blood-pressure-inputs input {
  width: 80px;
  margin-right: 5px;
}

.blood-pressure-inputs span {
  margin-right: 5px;
}

/* Tab Navigation */
.tab-navigation {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.tab-navigation button {
  flex: 1;
  padding: 12px;
  background-color: #17a2b8;
  color: white;
  border: none;
  border-radius: 5px;
  margin-right: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.tab-navigation button.active {
  background-color: #28a745;
}

.tab-navigation button:hover {
  background-color: #138496;
}

.tab-navigation button:active {
  transform: translateY(-1px);
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Reactive Styles */
.section button:disabled,
.prescription-form button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.section button:disabled:hover,
.prescription-form button:disabled:hover {
  transform: none;
}
